import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/avatar.svg";
import Tilt from "react-parallax-tilt";

function Home2() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
        <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }}>
              ABOUT <span className="blue"> ME </span>
            </h1>
            <p className="home-about-body">
              I fell in love with programming after I took intro CS class at college. 🤷‍♂️
              <br />
              <br />I am fluent in
              <i>
                <b className="blue"> Java, JavaScript and Python. </b>
              </i>
              <br />
              <br />
              My field of Interest's are &nbsp;
              <i>
                <b className="blue">Web Technologies </b> and
                {" "}
                <b className="blue">
                  Distributed System.
                </b>
              </i>
              <br />
              <br />
              However, more recently I have also been improving my computer hardware skills, especially in 
              {" "}
              <b className="blue">Computer Architecture</b> and
              <i>
                <b className="blue">
                  {" "}
                  System Verilog
                </b>
              </i>
            </p>
          </Col>
          <Col md={4} className="myAvtar">
            <Tilt>
              {/* <img src={myImg} className="img-fluid" alt="avatar" /> */}
            </Tilt>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default Home2;
