import React from "react";
import { Col, Row } from "react-bootstrap";
import {
  DiReact,
  DiNodejs,
  DiMysql,
} from "react-icons/di";
import {
  SiPytorch,
  SiFirebase,
  SiNextdotjs,
  SiNeo4J,
  SiSpringboot,
  SiPostgresql,
} from "react-icons/si";

function Libstack() {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
      <Col xs={4} md={2} className="tech-icons">
        <DiNodejs />
        <div class = "tech-name">Node.js</div>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiReact />
        <div class = "tech-name">React.js</div>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiNextdotjs />
        <div class = "tech-name">Next.js</div>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiSpringboot />
        <div class = "tech-name">Spring</div>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiPytorch />
        <div class = "tech-name">Pytorch</div>
      </Col>
      {/* Cloud tools */}
      
      {/* Sql and noSql */}
      <Col xs={4} md={2} className="tech-icons">
        <SiPostgresql />
        <div class = "tech-name">PostgreSQL</div>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiMysql />
        <div class = "tech-name">MySQL</div>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiFirebase />
        <div class = "tech-name">Firebase</div>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiNeo4J />
        <div class = "tech-name">Neo4j</div>
      </Col>
    </Row>
  );
}

export default Libstack;
