import React from "react";
import { Col, Row } from "react-bootstrap";
import {
  DiGit,
} from "react-icons/di";
import {
  SiDocker,
  SiAmazonaws,
  SiNetlify,
  SiGooglecloud,
} from "react-icons/si";

function Libstack() {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
      <Col xs={4} md={2} className="tech-icons">
        <SiAmazonaws />
        <div class = "tech-name">AWS</div>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiGooglecloud />
        <div class = "tech-name">GCP</div>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiNetlify />
        <div class = "tech-name">Netlify</div>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiGit />
        <div class = "tech-name">Git</div>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiDocker />
        <div class = "tech-name">Docker</div>
      </Col>
    </Row>
    
  );
}

export default Libstack;
