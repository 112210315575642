import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import audio from "../../Assets/Projects/audioviz.png";
import arm from "../../Assets/Projects/ARM.png";
import chatify from "../../Assets/Projects/chatify.png";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple"> Projects </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={chatify}
              isBlog={false}
              title="Husky Saver"
              description="The only thing with a dog more than its owner is its collar; what if it could protect your dog too?
               Our team is focused on creating a smart dog collar that can alert the owner if their pet is in a dangerous environment where they could potentially suffer a heat stroke"
              ghLink="https://github.com/soumyajit4419/Chatify"
              demoLink="https://embeddedcapstone22.wordpress.com/project-proposal/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={chatify}
              isBlog={false}
              title="_together"
              description="Design and implement a social media mobile app similar to BeReal with the functionalities of: account creation via UW NetID,
               friend system, notifying when to take a picture, photographing capabilities, a friend and discovery feed, and a comment and reaction system"
              // ghLink="https://github.com/soumyajit4419/Bits-0f-C0de"
              // demoLink="https://blogs.soumya-jit.tech/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={audio}
              isBlog={false}
              title="Audio Visualizer"
              description="Created an VGA audio visualizer that visually displays the rhythm, loudness, tempo, and 
              frequency of music via animated imagery. I implemented the FIR filter using the FIFO buffer to scale 
              down or scale up the loudness of the audio to match the height constraint "
              // ghLink="https://github.com/soumyajit4419/Editor.io"
              // demoLink="https://editor.soumya-jit.tech/"              
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={chatify}
              isBlog={false}
              title="Piano & Music Player"
              description="Created simple digital piano and multimedia music playing & Piano device by utilizing a real-time preemptive operating system (RT-OS)."
              ghLink="https://github.com/rghj21/pianoAndmusicPlayer"
              // demoLink="https://plant49-ai.herokuapp.com/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={chatify}
              isBlog={false}
              title="Maze generator & Path finder"
              description=""
              // ghLink="https://github.com/soumyajit4419/AI_For_Social_Good"
              // demoLink="https://www.youtube.com/watch?v=dQw4w9WgXcQ&ab_channel=RickAstley" <--------Please include a demo link here
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={arm}
              isBlog={false}
              title="ARM Pipelined CPU"
              description="Designed a 64-bit ARM CPU with Pipelining. It can take up to 11 different instructions that is written in machine language using System Verilog"
              ghLink="https://github.com/rghj21/pipelined_cpu"
              // demoLink="https://blogs.soumya-jit.tech/"      <--------Please include a demo link here 
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={chatify}
              isBlog={false}
              title="Derivative calculator"
              description="Derivative Calculator is a website/application that aims to provide such calculations under a simple streamlined UI. With simple yet effective features
              such as deriving by a specific variable, a step-by-step solution and a button to derive the equation"
              ghLink="https://github.com/Husky-Coding-Project/derivative-calc"
              demoLink=""              
            />
          </Col>

        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
